import {
    assertIsArray,
    assertIsBoolean,
    assertIsNonEmptyString,
    assertIsObject,
    assertObjectHasKey,
    assertIsNumber,
} from '../../lib/test-and-assert/assert-base';
import {
    testIsArray,
    testIsBoolean,
    testIsNonEmptyString,
    testIsNull,
    testIsObject,
    testIsTrueish,
    testIsNumberIsh,
} from '../../lib/test-and-assert/test-base';
import { testIsUserSetting } from './test';
import { assertIsUserProduct, assertIsUserSetting } from './assert';

const SESSION_STORAGE_USER = '__SESSION_STORAGE_USER__';

export { SESSION_STORAGE_USER };

class User {
    static ROLE_ANONYMOUS = 'ROLES_ANONYMOUS';
    static ROLE_DEFAULT = 'ROLE_USER';
    static ROLE_USER = 'ROLE_USER';
    static ROLE_USER_NO_PASSWORD = 'ROLE_USER_NO_PASSWORD';
    static ROLE_USER_PROFESSIONAL = 'ROLE_USER_PROFESSIONAL';
    static ROLE_USER_EDUCATIONAL = 'ROLE_USER_EDUCATIONAL';
    static ROLE_USER_ELIGIBLE_FOR_TRIAL = 'ROLE_USER_ELIGIBLE_FOR_TRIAL';
    static ROLE_CONTENT_PUBLISHER = 'ROLE_CONTENT_PUBLISHER';
    static ROLE_ADMIN = 'ROLE_ADMIN';
    static ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN';
    static ROLE_ADMIN_HISTORY_VIEW = 'ROLE_ADMIN_HISTORY_VIEW';
    static ROLE_ADMIN_HISTORY_EDIT = 'ROLE_ADMIN_HISTORY_EDIT';
    static ROLE_ADMIN_USER_VIEW = 'ROLE_ADMIN_USER_VIEW';
    static ROLE_ADMIN_USER_EDIT = 'ROLE_ADMIN_USER_EDIT';
    static ROLE_ADMIN_JOB_VIEW = 'ROLE_ADMIN_JOB_VIEW';
    static ROLE_ADMIN_JOB_EDIT = 'ROLE_ADMIN_JOB_EDIT';
    static ROLE_ADMIN_FINANCIAL_VIEW = 'ROLE_ADMIN_FINANCIAL_VIEW';
    static ROLE_ADMIN_FINANCIAL_EDIT = 'ROLE_ADMIN_FINANCIAL_EDIT';
    static ROLE_ADMIN_FINANCIAL_MASTER = 'ROLE_ADMIN_FINANCIAL_MASTER';
    static ROLE_ADMIN_COUPON = 'ROLE_ADMIN_COUPON';
    static ROLE_ADMIN_DEBUG_LOGS_VIEW = 'ROLE_ADMIN_DEBUG_LOGS_VIEW';
    static ROLE_ADMIN_DEBUG_API_KEY_VIEW = 'ROLE_ADMIN_DEBUG_API_KEY_VIEW';
    static ROLE_ADMIN_SUPER_DEBUG_TOOL = 'ROLE_ADMIN_SUPER_DEBUG_TOOL';
    static ROLE_USER_ELIGIBLE_FOR_EDUCATIONAL = 'ROLE_USER_ELIGIBLE_FOR_EDUCATIONAL';
    static ROLE_USER_NEEDS_VERIFICATION_FOR_EDUCATIONAL = 'ROLE_USER_NEEDS_VERIFICATION_FOR_EDUCATIONAL';
    static ROLE_USER_STARTER = 'ROLE_USER_STARTER';
    static ROLE_USER_PRO = 'ROLE_USER_PRO';
    static ROLE_USER_ULTIMATE = 'ROLE_USER_ULTIMATE';

    constructor(data) {
        this._id = testIsNumberIsh(data?.id) ? data?.id : 0;
        this._username = testIsNonEmptyString(data?.username) ? data?.username : null;
        this._usernameCanonical = testIsNonEmptyString(data?.username_canonical) ? data?.username_canonical : null;
        this._email = testIsNonEmptyString(data?.email) ? data?.email : null;
        this._plainEmail = testIsNonEmptyString(data?.plain_email) ? data?.plain_email : null;
        this._emailCanonical = testIsNonEmptyString(data?.email_canonical) ? data?.email_canonical : null;
        this._isEnabled = testIsBoolean(data?.is_enabled) ? data?.is_enabled : false;
        this._createdAt = testIsNonEmptyString(data?.created_at) ? data?.created_at : null;
        this._roles = testIsArray(data?.roles) ? data?.roles : [];
        this._hasNewsletter = testIsBoolean(data?.has_newsletter) ? data?.has_newsletter : false;
        this._extra = testIsObject(data?.extra) ? data?.extra : null;
        this._language = testIsNonEmptyString(data?.language) ? data?.language : null;
        this._userProfile = testIsArray(data?.user_profile) ? data?.user_profile : null;
        this._products = testIsArray(data?.products) ? data?.products : [];
        this._isPremium = testIsBoolean(data?.is_premium) ? data?.is_premium : false;
        this._isStarter = testIsBoolean(data?.is_starter) ? data?.is_starter : false;
        this._isPro = testIsBoolean(data?.is_pro) ? data?.is_pro : false;
        this._isUltimate = testIsBoolean(data?.is_ultimate) ? data?.is_ultimate : false;
        this._isActiveSubscription = testIsBoolean(data?.active_subscription) ? data?.active_subscription : false;
        this._isEligibleForFreeTrial = testIsBoolean(data?.is_eligible_for_free_trial)
            ? data?.is_eligible_for_free_trial
            : false;
        this._settings = testIsArray(data?.settings) ? data?.settings : [];
        this._userUm = testIsObject(data?.user_um) ? data?.user_um : null;
        this._isBusinessUser = testIsBoolean(data?.is_business_user) ? data?.is_business_user : false;
        this._isRegistered = testIsBoolean(data?.is_registered) ? data?.is_registered : false;
        this._isEducational = testIsBoolean(data?.is_educational) ? data?.is_educational : false;
        this._isAnonymous = testIsBoolean(data?.is_anonymous) ? data?.is_anonymous : false;
        this._availableCredits = testIsNumberIsh(data?.available_credits) ? data?.available_credits : 0;
        this._availableCreditsOnceActive = testIsNumberIsh(data?.available_credits_once_active) ? data?.available_credits_once_active : 0;
        this._productLevel = testIsNumberIsh(data?.product_level) ? data?.product_level : null;
        this._legacySubscription = testIsBoolean(data?.legacy_subscription) ? data?.legacy_subscription : false;
        this._daysUntilRenewal = testIsNumberIsh(data?.days_until_renewal) ? data?.days_until_renewal : 0;
        this._rawMeData = data?.raw_me_data ?? null;
    }

    /**
     * @type {?String}
     */
    _username = null;

    /**
     * @type {?String}
     */
    _usernameCanonical = null;

    /**
     * @type {?String}
     */
    _email = null;

    /**
     * @type {?String}
     */
    _plainEmail = null;

    /**
     * @type {?String}
     */
    _emailCanonical = null;

    /**
     * @type {?String}
     */
    _password = null;

    /**
     * @type {Boolean}
     */
    _isEnabled = false;

    /**
     * @type {?String}
     */
    _createdAt = null;

    /**
     * @type {String[]}
     */
    _roles = [];

    /**
     * @type {Boolean}
     */
    _hasNewsletter = false;

    /**
     * @type {?Object}
     */
    _extra = null;

    /**
     * @type {?String}
     */
    _language = null;
  
    /**
     * @type {[]}
     */
    _userProfile  = null;

    /**
     * @type {UserProduct[]}
     */
    _products = [];

    /**
     * @type {Boolean}
     */
    _isPremium = false;

    /**
     * @type {Boolean}
     */
    _isStarter = false;

    /**
     * @type {Boolean}
     */
    _isPro = false;

    /**
     * @type {Boolean}
     */
    _isUltimate = false;

    /**
     * @type {Boolean}
     */
    _isActiveSubscription = false;

    /**
     * @type {Boolean}
     */
    _isEligibleForFreeTrial = false;

    /**
     * @type {UserSetting[]}
     */
    _settings = [];

    /**
     * @type {?UmUser}
     */
    _userUm = null;

    /**
     * @type {Boolean}
     */
    _isBusinessUser = false;

    /**
     * @type {Boolean}
     */
    _isRegistered = false;

    /**
     * @type {Boolean}
     */
    _isEducational = false;

    /**
     * @type {Boolean}
     */
    _isAnonymous = false;

    /**
     * @type {?MeData}
     */
    _rawMeData = null;

    _id = 0;
    _availableCredits = 0;
    _availableCreditsOnceActive = 0;
    _daysUntilRenewal = 0;
    _productLevel = null;
    _legacySubscription = false;

    /**
     * @param {String} username
     *
     * @return {User}
     */
    setUsername(username) {
        assertIsNonEmptyString(username);

        this._username = username;

        return this;
    }

    /**
     * @return {?String}
     */
    getUsername() {
        return this._username;
    }

    /**
     * @param {Number} id
     *
     * @return {User}
     */
    setId(id) {
        assertIsNumber(id);

        this._id = id;

        return this;
    }

    /**
     * @return {?Number}
     */
    getId() {
        return this._id;
    }

    /**
     * @param {String} usernameCanonical
     *
     * @return {User}
     */
    setUsernameCanonical(usernameCanonical) {
        assertIsNonEmptyString(usernameCanonical);

        this._usernameCanonical = usernameCanonical;

        return this;
    }

    /**
     * @return {?String}
     */
    getUsernameCanonical() {
        return this._usernameCanonical;
    }

    /**
     * @param {String} email
     *
     * @return {User}
     */
    setEmail(email) {
        assertIsNonEmptyString(email);

        this._email = email;

        return this;
    }

    /**
     * @return {?String}
     */
    getEmail() {
        return this._email;
    }

    /**
     * @param {String} plainEmail
     *
     * @return {User}
     */
    setPlainEmail(plainEmail) {
        assertIsNonEmptyString(plainEmail);

        this._plainEmail = plainEmail;

        return this;
    }

    /**
     * @return {?String}
     */
    getPlainEmail() {
        return this._plainEmail;
    }

    /**
     * @param {String} emailCanonical
     *
     * @return {User}
     */
    setEmailCanonical(emailCanonical) {
        assertIsNonEmptyString(emailCanonical);

        this._emailCanonical = emailCanonical;

        return this;
    }

    /**
     * @return {?String}
     */
    getEmailCanonical() {
        return this._emailCanonical;
    }

    /**
     * @param {String} password
     *
     * @return {User}
     */
    setPassword(password) {
        assertIsNonEmptyString(password);

        this._password = password;

        return this;
    }

    /**
     * @return {?String}
     */
    getPassword() {
        return this._password;
    }

    /**
     * @param {Boolean} isEnabled
     *
     * @return {User}
     */
    setIsEnabled(isEnabled) {
        assertIsBoolean(isEnabled);

        this._isEnabled = isEnabled;

        return this;
    }

    /**
     * @return {Boolean}
     */
    isEnabled() {
        return this._isEnabled;
    }

    /**
     * @param {String} createdAt
     *
     * @return {User}
     */
    setCreatedAt(createdAt) {
        assertIsNonEmptyString(createdAt);

        this._createdAt = createdAt;

        return this;
    }

    /**
     * @return {?String}
     */
    getCreatedAt() {
        return this._createdAt;
    }

    /**
     * @param {String[]} roles
     *
     * @return {User}
     */
    setRoles(roles) {
        assertIsArray(roles);
        roles.forEach(assertIsNonEmptyString);

        this._roles = roles;

        return this;
    }

    /**
     * @return {String[]}
     */
    getRoles() {
        return this._roles;
    }

    /**
     * @param {String} role
     *
     * @return {User}
     */
    addRole(role) {
        assertIsNonEmptyString(role);

        this._roles.push(role);

        return this;
    }

    /**
     * @param {String} role
     *
     * @return {Boolean}
     */
    hasRole(role) {
        assertIsNonEmptyString(role);

        const foundRole = this._roles.find((r) => role === r);

        return testIsNonEmptyString(foundRole);
    }

    /**
     * @param {Boolean} hasNewsletter
     *
     * @return {User}
     */
    setHasNewsletter(hasNewsletter) {
        assertIsBoolean(hasNewsletter);

        this._hasNewsletter = hasNewsletter;

        return this;
    }

    /**
     * @return {Boolean}
     */
    hasNewsletter() {
        return this._hasNewsletter;
    }

    /**
     * @param {Object} extra
     *
     * @return {User}
     */
    setExtra(extra) {
        assertIsObject(extra);

        this._extra = extra;

        return this;
    }

    /**
     * @return {?Object}
     */
    getExtra() {
        return this._extra;
    }

    /**
     * @param {String} key
     *
     * @return {undefined|any}
     */
    getExtraKey(key) {
        assertIsNonEmptyString(key);

        if (testIsNull(this._extra)) {
            return undefined;
        }

        assertObjectHasKey(this._extra, key);

        return this._extra[key];
    }

    /**
     * @param {String} key
     * @param {any} value
     *
     * @return {User}
     */
    addExtra(key, value) {
        assertIsNonEmptyString(key);

        if (testIsNull(this._extra)) {
            this._extra = {};
        }

        this._extra[key] = value;

        return this;
    }

    /**
     * @param {String} language
     *
     * @return {User}
     */
    setLanguage(language) {
        assertIsNonEmptyString(language);

        this._language = language;

        return this;
    }

    /**
     * @return {String}
     */
    getLanguage() {
        return this._language;
    }

    
    setUserProfile(profile) {
        assertIsArray(profile);
        this._userProfile = profile;

        return this;
    }

    /**
     * @return {Array}
     */
    getUserProfile() {
        return this._userProfile;
    }

    /**
     * @param {UserProduct[]} products
     *
     * @return {User}
     */
    setProducts(products) {
        assertIsArray(products);
        products.forEach(assertIsUserProduct);

        this._products = products;

        return this;
    }

    /**
     * @return {UserProduct[]}
     */
    getProducts() {
        return this._products;
    }

    getProductIds() {
        let products = this.getProducts();
        let ids = [];

        for (const product of products) {
            ids.push(product.id);
        }

        return ids;
    }

    /**
     * @param {UserProduct} product
     *
     * @return {User}
     */
    addProduct(product) {
        assertIsUserProduct(product);

        this._products.push(product);

        return this;
    }

    /**
     * @param {Boolean} isPremium
     *
     * @return {User}
     */
    setIsPremium(isPremium) {
        assertIsBoolean(isPremium);

        this._isPremium = isPremium;

        return this;
    }

    /**
     * @return {Boolean}
     */
    isPremium() {
        return this._isPremium;
    }

    setIsPro(isPro) {
        assertIsBoolean(isPro);

        this._isPro = isPro;

        return this;
    }

    /**
     * @return {Boolean}
     */
    isPro() {
        return this._isPro;
    }

    setIsUltimate(isUltimate) {
        assertIsBoolean(isUltimate);

        this._isUltimate = isUltimate;

        return this;
    }

    /**
     * @return {Boolean}
     */
    isUltimate() {
        return this._isUltimate;
    }

    setIsStarter(isStarter) {
        assertIsBoolean(isStarter);

        this._isStarter = isStarter;

        return this;
    }

    /**
     * @return {Boolean}
     */
    isStarter() {
        return this._isStarter;
    }

    /**
     * @param {Boolean} isActiveSubscription
     *
     * @return {User}
     */
    setIsActiveSubscription(isActiveSubscription) {
        assertIsBoolean(isActiveSubscription);

        this._isActiveSubscription = isActiveSubscription;

        return this;
    }

    /**
     * @return {Boolean}
     */
    isActiveSubscription() {
        return this._isActiveSubscription;
    }

    /**
     * @param {Boolean} isEligibleForFreeTrial
     *
     * @return {User}
     */
    setIsEligibleForFreeTrial(isEligibleForFreeTrial) {
        assertIsBoolean(this._isPremium);

        this._isEligibleForFreeTrial = isEligibleForFreeTrial;

        return this;
    }

    /**
     * @return {Boolean}
     */
    isEligibleForFreeTrial() {
        return this._isEligibleForFreeTrial;
    }

    /**
     * @param {UserSetting[]} settings
     *
     * @return {User}
     */
    setSettings(settings) {
        assertIsArray(settings);
        settings.forEach(testIsUserSetting);

        this._settings = settings;

        return this;
    }

    /**
     * @return {UserSetting[]}
     */
    getSettings() {
        return this._settings;
    }

    /**
     * @param {String} key
     *
     * @return {undefined|UserSetting}
     */
    getSetting(key) {
        assertIsNonEmptyString(key);

        return this._settings.find((setting) => setting.settings_key === key);
    }

    /**
     * @param {UserSetting} setting
     *
     * @return {User}
     */
    addSetting(setting) {
        assertIsUserSetting(setting);

        this._settings.push(setting);

        return this;
    }

    /**
     * @param {UmUser} userUm
     *
     * @return {User}
     */
    setUserUm(userUm) {
        assertIsObject(userUm);

        this._userUm = userUm;

        return this;
    }

    /**
     * @return {?UmUser}
     */
    getUserUm() {
        return this._userUm;
    }

    /**
     * @param {Boolean} isBusinessUser
     *
     * @return {User}
     */
    setIsBusinessUser(isBusinessUser) {
        assertIsBoolean(isBusinessUser);

        this._isBusinessUser = isBusinessUser;

        return this;
    }

    /**
     * @return {Boolean}
     */
    isBusinessUser() {
        return this._isBusinessUser;
    }

    /**
     * @param {Boolean} isRegistered
     *
     * @return {User}
     */
    setIsRegistered(isRegistered) {
        assertIsBoolean(isRegistered);

        this._isRegistered = isRegistered;

        return this;
    }

    /**
     * @return {Boolean}
     */
    isRegistered() {
        return this._isRegistered;
    }

    /**
     * @return {Boolean}
     */
    isEducational() {
        return this._isEducational;
    }

    /**
     * @param {Boolean} isEducational
     *
     * @return {User}
     */
    setIsEducational(isEducational) {
        assertIsBoolean(isEducational);

        this._isEducational = isEducational;

        return this;
    }

    /**
     * @param {Boolean} isAnonymous
     *
     * @return {User}
     */
    setIsAnonymous(isAnonymous) {
        assertIsBoolean(isAnonymous);

        this._isAnonymous = isAnonymous;

        return this;
    }

    /**
     * @return {Boolean}
     */
    isAnonymous() {
        return this._isAnonymous;
    }

    isFromEurope() {
        return testIsTrueish(this.getExtraKey('europe'));
    }

    isEligibleForEducation() {
        return this.hasRole(User.ROLE_USER_ELIGIBLE_FOR_EDUCATIONAL);
    }

    setRawMeData(meData) {
        this._rawMeData = meData;
    }

    getRawMeData() {
        return this._rawMeData;
    }

    setAvailableCredits(availableCredits) {
        assertIsNumber(availableCredits);

        this._availableCredits = availableCredits;

        return this;
    }

    getAvailableCredits() {
        return this._availableCredits;
    }

    setAvailableCreditsOnceActive(availableCreditsOnceActive) {
        assertIsNumber(availableCreditsOnceActive);

        this._availableCreditsOnceActive = availableCreditsOnceActive;

        return this;
    }

    getAvailableCreditsOnceActive() {
        return this._availableCreditsOnceActive;
    }

    setProductLevel(productLevel) {
        assertIsNumber(productLevel);

        this._productLevel = productLevel;

        return this;
    }

    getProductLevel() {
        return this._productLevel;
    }

    setLegacySubscription(legacySubscription) {
        assertIsBoolean(legacySubscription);

        this._legacySubscription = legacySubscription;

        return this;
    }

    isLegacySubscription() {
        return this._legacySubscription;
    }

    setDaysUntilRenewal(daysUntilRenewal) {
        assertIsNumber(daysUntilRenewal);

        this._daysUntilRenewal = daysUntilRenewal;

        return this;
    }

    getDaysUntilRenewal() {
        return this._daysUntilRenewal;
    }

    /**
     * This returns a User object from the session storage. If it is not available
     * or contains wrong data it returns null
     *
     * @return {User|null}
     */
    static loadUserDataFromSessionStorage() {
        try {
            const encoded = window.sessionStorage.getItem(SESSION_STORAGE_USER);

            /** @type {MeData} */
            const meData = JSON.parse(encoded);

            return this.createFromMe(meData);
        } catch (e) {}

        return null;
    }

    /**
     * Create a user object from data retrieved from the /me endpoint
     *
     * @param {MeData} meData
     *
     * @return {User}
     */
    static createFromMe(meData) {
        const user = new User();

        user.setRawMeData(meData);

        if (testIsNonEmptyString(meData.username)) {
            user.setUsername(meData.username);
        }

        if (testIsNonEmptyString(meData.username_canonical)) {
            user.setUsernameCanonical(meData.username_canonical);
        }

        if (testIsNonEmptyString(meData.email)) {
            user.setEmail(meData.email);
        }

        if (testIsNonEmptyString(meData.plain_email)) {
            user.setPlainEmail(meData.plain_email);
        }

        if (testIsNonEmptyString(meData.email_canonical)) {
            user.setEmailCanonical(meData.email_canonical);
        }

        if (testIsBoolean(meData.enabled)) {
            user.setIsEnabled(meData.enabled);
        }

        if (testIsNonEmptyString(meData.created_at)) {
            user.setCreatedAt(meData.created_at);
        }

        if (testIsArray(meData.roles)) {
            user.setRoles(meData.roles);

            if (meData.roles.includes(User.ROLE_ANONYMOUS)) {
                user.setIsAnonymous(true);
            }

            if (meData.roles.includes(User.ROLE_USER)) {
                user.setIsRegistered(true);
            }

            if (meData.roles.includes(User.ROLE_USER_ELIGIBLE_FOR_TRIAL)) {
                user.setIsEligibleForFreeTrial(true);
            }
        }

        if (testIsBoolean(meData.newsletter)) {
            user.setHasNewsletter(meData.newsletter);
        }

        if (testIsObject(meData.extra)) {
            user.setExtra(meData.extra);
        }

        if (testIsNonEmptyString(meData.language)) {
            user.setLanguage(meData.language);
        }

        if (testIsArray(meData.user_profile)) {
            user.setUserProfile(meData.user_profile);
        }

        if (testIsArray(meData.products)) {
            user.setProducts(meData.products);
        }

        if (testIsBoolean(meData.premium)) {
            user.setIsPremium(meData.premium);
        }

        if (testIsBoolean(meData.starter)) {
            user.setIsStarter(meData.starter);
        }

        if (testIsBoolean(meData.pro)) {
            user.setIsPro(meData.pro);
        }

        if (testIsBoolean(meData.ultimate)) {
            user.setIsUltimate(meData.ultimate);
        }

        if (testIsBoolean(meData.active_subscription)) {
            user.setIsActiveSubscription(meData.active_subscription);
        }

        if (testIsBoolean(meData.educational)) {
            user.setIsEducational(meData.educational);
        }

        if (testIsBoolean(meData.legacy_subscription)) {
            user.setLegacySubscription(meData.legacy_subscription);
        }

        if (!user.isRegistered()) {
            user.setIsEligibleForFreeTrial(true);
        }

        if (testIsArray(meData.user_settings)) {
            user.setSettings(meData.user_settings);
        }

        if (testIsObject(meData.user_um)) {
            user.setUserUm(meData.user_um);
        }

        if (testIsBoolean(meData.business_user)) {
            user.setIsBusinessUser(meData.business_user);
        }

        if (testIsNumberIsh(meData.available_credits)) {
            user.setAvailableCredits(meData.available_credits);
        }

        if (testIsNumberIsh(meData.available_credits_once_active)) {
            user.setAvailableCreditsOnceActive(meData.available_credits_once_active);
        }

        if (testIsNumberIsh(meData.product_level)) {
            user.setProductLevel(meData.product_level);
        }

        if (testIsNumberIsh(meData.days_until_renewal)) {
            user.setDaysUntilRenewal(meData.days_until_renewal);
        }

        return user;
    }

    /**
     * Called by JSON.stringify to get a representation of the user as a serializable object
     *
     * @return {Object}
     */
    toJSON() {
        return {
            username: this.getUsername(),
            username_canonical: this.getUsernameCanonical(),
            email: this.getEmail(),
            plain_email: this.getPlainEmail(),
            email_canonical: this.getEmailCanonical(),
            is_enabled: this.isEnabled(),
            created_at: this.getCreatedAt(),
            roles: this.getRoles(),
            has_newsletter: this.hasNewsletter(),
            extra: this.getExtra(),
            language: this.getLanguage(),
            userProfile: this.getUserProfile(),
            products: this.getProducts(),
            is_premium: this.isPremium(),
            is_pro: this.isPro(),
            is_ultimate: this.isUltimate(),
            is_starter: this.isStarter(),
            is_eligible_for_free_trial: this.isEligibleForFreeTrial(),
            settings: this.getSettings(),
            user_um: this.getUserUm(),
            is_business_user: this.isBusinessUser(),
            is_registered: this.isRegistered(),
            is_educational: this.isEducational(),
            is_anonymous: this.isAnonymous(),
            available_credits: this.getAvailableCredits(),
            available_credits_once_active: this.getAvailableCreditsOnceActive(),
            product_level: this.getProductLevel(),
            legacy_subscription: this.isLegacySubscription(),
            days_until_renewal: this.getDaysUntilRenewal(),
            raw_me_data: this.getRawMeData(),
        };
    }

    /**
     * @param {String} jsonUser
     *
     * @return {User}
     */
    static createFromJSON(jsonUser) {
        const decodedUser = JSON.parse(jsonUser);

        return new User(decodedUser);
    }
}

export { User };
